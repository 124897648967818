.ReactModal__Overlay {
  z-index: 1000;
}

.ReactModal__Content {
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%) translateY(-50%);
  width: 500px;
  height: max-content;
  max-height: 80vh;
}

@media (min-height: 900px) {
  .ReactModal__Content {
    max-height: 95vh;
  }
}

.modal-body {
  height: 65vh;
}

.details-display-wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}

.details-display-wrap.one-line {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
}

.details-display {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.details-display.flex-mode {
  display: flex;
  flex-direction: row;
  grid-gap: 0px;
}

.details-display.flex-mode>div:not(:only-child):last-child {
  /* margin-left: 20px; */
}

.details-display-wrap .icon-wrapper .fc-title-icon {
  margin-right: 0px;
}

.btn-padding {
  padding: 10px 0;
}

.ra-field {
  margin: 8px -16px;
}

.modal-header {
  border-bottom: 1px #ccc solid;
  margin-bottom: 10px;
  position: sticky;
  top: -20px;
  z-index: 20;
  padding: 20px 20px;
  margin: -20px -20px 5px;
  background: #f1f1f1;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.button-blue {
  padding: 6px 16px;
  background-color: #2196f3;
  color: white;
  border-radius: 4px;
  display: inline-block;
}

.button-red {
  background-color: red;
}

.button-blue[disabled] {
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
}

.secondary-btn {
  padding: 6px 10px;
  background-color: transparent;
  color: #888;
  border-radius: 4px;
  display: inline-block;
  box-shadow: 0 0 0 1px #ccc inset;
}

button.fc-prev-button,
button.fc-next-button,
button.fc-today-button,
button.fc-button-primary {
  border-color: #ccc;
  background-color: transparent;
  color: #2196f3;
}

button.fc-button-primary:hover {
  background-color: #2196f3;
}

.fc-toolbar .fc-button-primary:not(:disabled).fc-button-active {
  border-color: #ccc;
  background-color: #ddd;
  color: #333;
}

.fc-toolbar .fc-button-primary:disabled {
  background-color: transparent;
  border-color: #ccc;
  opacity: 0.3;
  color: #ccc;
}

.fc-toolbar {
  margin: 1em 0;
}

.fc-toolbar h2 {
  font-size: 20px !important;
  font-weight: bold;
}

.calendar-wrapper {
  padding: 20px 0px 10px;
  max-width: 610px;
  z-index: 1000;
}

input.field-border,
select.field-border {
  border: 1px solid #cccccc;
  padding: 5px;
  margin-left: 15px;
  border-radius: 4px;
  min-width: 180px;
  min-height: 36px;
}

.icon-wrapper {
  display: inline-block;
  padding: 3px;
  margin-right: 10px;
  font-size: 12px;
}

.icon-fontsize {
  font-size: 45px !important;
}

.icon-wrapper svg {
  width: 16px;
  vertical-align: middle;
  margin-top: -2px;
}

.big-icon-wrapper {
  margin-bottom: 20px;
}

.big-icon-wrapper svg {
  width: 50px;
  vertical-align: middle;
  height: 50px;
  margin-right: 20px;
}

.extra-margin-top {
  margin-top: 6em;
  position: relative;
}

.tempClass {
  position: relative;
}

.fc-title-icon {
  font-family: 'Material Icons';
  /* content: "chevron_right"; */
  /* content: attr(title) ' '; */
  display: inline-block;
  margin-right: 3px;
  /* font-size: 20px; */
  vertical-align: middle;
}

/* .member .fc-title:before {
  content: 'star';
}
.birthday .fc-title:before {
  content: 'cake';
}
.minor .fc-title:before {
  content: 'escalator_warning';
}
.vip .fc-title:before {
  content: 'badge';
}
.new .fc-title:before {
  content: 'fiber_new';
}
.pregnant .fc-title:before {
  content: 'pregnant_woman';
} */
.fc-event {
  /* height: fit-content;
  display: table !important; */
  width: 100%;
  font-size: 0.8rem !important;
}

.biggerTabs button.MuiTab-root {
  font-size: 1.15em;
  max-width: none;
  text-transform: none;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: -0.5px;
  /* background: #ececec; */
  margin: 0 2px;
  /* border-radius: 6px 6px 0 0; */
}

.biggerTabs button.MuiTab-root:first-child {
  margin-left: 15px;
}

.scroll-within {
  max-height: none;
  padding: 20px;
  background-color: white;
}

.max60vh .modal-body {
  height: auto;
}

.max60vh .ra-field.modal-body {
  overflow-y: auto;
  height: auto;
  max-height: 70vh;
  padding: 20px 20px 20px 25px;
  margin: -8px -36px -20px;
}

.max60vh .ra-field.modal-body .simple-form {
  padding: 20px 0;
}

.ReactModal__Content {
  box-shadow: 0 0 4px rgba(0, 0, 0, 40%);
  border-radius: 6px;
  overflow: hidden;
  position: absolute;
  outline: 0px;
}

.w550px {
  width: 550px !important;
}

.details-display.grid4060 {
  grid-template-columns: 40% auto;
}

.details-display.grid4060.list .text-area-border {
  width: 100%;
  padding: 8px;
}

.simpleform-details-display .MuiFormControl-root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: auto;
  align-items: baseline;
}

/* .simpleform-details-display .RaLabeled {} */
.select-no-label label.MuiFormLabel-root {
  transform: translate(15px, 46px) !important;
}

.select-no-label label.Mui-focused,
.select-no-label label.MuiFormLabel-filled {
  opacity: 0;
  z-index: -1;
}

.simple-form .MuiFormControl-root label {
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 18px;
}

.simple-form .MuiFilledInput-input {
  padding: 15px 15px !important;
}

.guest-notes-main-wrap {
  grid-column: 1 / span 2;
}

.guest-notes-main-wrap>div {
  width: 100%;
}

.guest-notes-main-wrap .MuiCard-root {
  box-shadow: none !important;
}

.guest-notes-wrap {
  padding: 0px !important;
  padding-top: 20px !important;
  /* border-top: 1px solid #ccc; */
  width: 100%;
}

.guest-notes {
  color: black;
  font-size: medium;
}

.guest-notes-wrap>div>div>div {
  width: 100%;
}

/* MuiInputLabel-filled MuiFormLabel-filled Mui-focused Mui-focused */

/* MuiFormControl-root MuiTextField-root RaSelectInput-input-65 select-no-label MuiFormControl-marginDense
MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled */

.text-area-border {
  border: 1px black solid;
  border-radius: 5px;
}

.w-150 {
  min-width: 130px !important;
  max-width: 130px;
  max-height: 36px;
}

@media (max-width: 730px) {
  .details-display.one {
    grid-template-columns: 1fr;
  }
}

.fc-today {
  background: white !important;
}

.ratting-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.light-text {
  font-weight: bold;
}

.legen-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;
}

.legen-icon-wrapper:first-child {
  margin-left: 0px;
}

.legen-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.legen-icon {
  margin-right: 0px;
  text-align: right;
  font-size: 20px;
  line-height: 27px;
  width: 20px;
}

.legen-name {
  font-size: 14px;
}

.details-display-wrap .details-display label {
  font-size: 14px;
}

.details-display-wrap .details-display input {
  margin-left: 0px;
  max-width: 100px !important;
  min-width: 100px !important;
}

.details-display-wrap .details-display select {
  margin-left: 0px;
  max-width: 200px !important;
  min-width: 100px !important;
}

.details-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 20px;
}

header.MuiPaper-root button[aria-label="Refresh"] {
  display: none;
}
