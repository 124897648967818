.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

.select-reader-input-wrapper {
  display: flex; 
  gap: 0.5rem;
}

.select-reader {
  border-style: none;
  border-radius: 0.5rem;
  padding: 0.3rem;
  background-color: #ced7de;
}

.select-reader:focus{
  outline: none;
}

.select-reader option {
  border: 1px solid #ced7de;
  background-color: #fff;
}

.text-red {
  color: red;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
