@font-face {
  font-family: 'MrEavesSanOT';
  font-style: normal;
  font-weight: 400;
  src: url('/src/styles/fonts/mreavessanot-bold.otf') format('truetype');
  font-display: swap;
}

.liability-popup{
  max-height: 100vh !important;
  overflow: auto!important;
  background: #fff;
  padding: 0!important;
}

.btn {
  background: #fff;
  border: 1px solid #808080;
  border-radius: 1.59375rem;
  color: #8dd1d8;
  cursor: pointer;
  font-family: 'MrEavesSanOT', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0.5625rem 0.75rem;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: font-size 400ms ease-in-out, padding 400ms ease-in-out;
  transition: font-size 400ms ease-in-out, padding 400ms ease-in-out;
}

.header-font {
  color: #fff;
    font-family: "MrEavesSanOT",serif;
    font-size: 4.25rem;
    font-weight: 900;
    letter-spacing: 3px;
    line-height: 1;
    margin: 0.3em 0;
    position: relative;
    letter-spacing: 6px;
}
.header-font::before{
  content: attr(title);
  position: absolute;
  width: 100%;
  color: rgba(141,209,216,.4);
    z-index: 1;
    color: rgba(141,209,216,.4);
    left: 0.0625rem;
    right: 0.3125rem;
    top: 0.1875rem;
    z-index: 1;

}
.header-font::after{

  content: attr(title);
  position: absolute;
  width: 100%;
  color: #fff;
  left: 0.3125rem;
  right: 0.0625rem;
  text-shadow: -1px -1px 0 #9a9a9a, 1px -1px 0 #9a9a9a, -1px 1px 0 #9a9a9a, 1px 1px 0 #9a9a9a;
  top: 0.3125rem;
  z-index: 0;
}

.newfont {
  font-size: 30px !important;
}

.own-header {
  max-width: 1024px;
  font-size: 51px;
  margin-top: 0rem;
}

.header-hdr {
  letter-spacing: 6px;
  padding: 0.5rem;
  text-align: center;
  position: relative;
  padding: 0.5rem;
    text-align: center;
    margin-top: 4rem;
}


.btn--secondary {
  background: #8dd1d8;
  border: 1px solid #8dd1d8;
  color: #fff;
  font-family: inherit;
  line-height: initial;
}

.component-heading {
  color: #8dd1d8;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.liabilityContainer {
  width: 85%;
  margin: 0 auto;
  padding-bottom: 50px;
  color: grey;
  line-height: 1.15;
}

.signWaiver,
.d-flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signWaiver > div {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.signWaiver p {
  border: none;
  border-top: 1px solid gray;
  border-radius: 0;
  margin-top: 5px;
}

.signBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signWaiver .signBtn p {
  border: none;
}

.signContainer ul {
  font-size: 19px;
}

.signContainer h4 {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
}

.signContainer section span {
  margin-bottom: 30px;
  display: block;
  font-size: 19px;
  line-height: 1.15;

}
.guardian-data{
  display: flex;
}
.liabilityContainer .guardian-data{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.signContainer .sigContainer canvas {
  width: 500px !important;
  height: 200px !important;
  margin: 10px 0px !important;
  background-color: #f1f1f1 !important;
}
.signContainer .header-hdr {
  text-align: left;
  padding-left: 0px;
}

@media (max-width: 660px) {
  .signContainer .ml60 {
    margin-left: 0px !important;
  }
  .signContainer .sigContainer canvas {
    width: 100% !important;
    height: 150px !important;
  }

  .signContainer h4 {
    font-size: 17px;
  }
  .signWaiver {
    flex-direction: column;
  }
  .signContainer section span {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .signContainer input {
    border: none !important;
  }
}
.newfont {
  font-size: 30px !important;
  text-align: center;
}
.sigContainer .header-font:before {
  top: 0.225rem;
}
.new-sign {
  justify-content: center !important;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
