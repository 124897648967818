.optionSelectionBlock {
  margin-top: 0em;
  margin-bottom: 2em;
  padding: 0em 0.7em;
}

.prefFocusPressure {
  margin: 1em auto;
  max-width: 1000px;
}

.prefFocusPressure .optionBlock {
  flex: 1 1 0px;
  font-weight: bold;
  display: block;
  min-height: 1px;
  padding: 0.3em;
}

.min-w-500 {
  min-width: 500px;
}

.prefFocusPressure .mainLabels .optionBlock .lables.amoutOfTime {
  color: #626262;
}

.prefFocusPressure .optionBlock p {
  padding-left: 16px;
  margin: 0em;
}

.prefFocusPressure .optionBlock .lables {
  font-size: 1.3em;
  text-transform: uppercase;
  font-weight: 600;
  color: #808080;
  text-align: center;
}
.prefFocusPressure .optionBlock .lables.amoutOfTime {
  font-size: 1em;
  font-weight: bold;
  color: #808080;
  padding-top: 0.5em;
}

.prefFocusPressure .optionBlock .lables span {
  display: block;
  font-size: 0.8em;
}

.prefFocusPressure .optionBlock {
  font-weight: 700;
  display: block;
  min-height: 1px;
  padding: 0.3em 0em;
}

.prefFocusPressure .mainLabelsWrap {
  order: 1;
}

/* Option List styles */
.optionLists {
  display: flex;
}

.lablesOptions .optionLists {
  align-items: center;
}

.optionLists .optionBlock:nth-child(3) > div > div > div:last-child > div {
  color: #626262;
}

.figureNext {
  align-self: flex-end;
}

/* Figure next styles */
.figureNext .svgFigure {
  margin: 0em 0.3em;
  transform: scale(1.2);
  transform-origin: center;
}

.figureNext.front .svgFigure {
  transform: scale(1.1);
}

.figureNext .nextBtn button {
  display: flex;
  align-items: center;
}

/* General styles */

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.flex-only {
  display: flex;
}

.flex-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.is-flex {
  flex: 1;
}

.flex-items {
  flex: 1 1 0px;
}

.flex-items-zero {
  flex: 0 0 0px;
}

.flex-item-5p {
  flex: 0 0 5%;
}

.flex-item-40p {
  flex: 1 1 40%;
}

.flex-item-50p {
  flex: 1 1 50%;
}

.flex-item-60p {
  flex: 1 1 60%;
}

.flex-direction-col {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

/* Loader styles */

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mainLabels .optionBlock {
  flex: 1;
  margin-right: 0.5em;
}

.optionLists .optionBlock {
  flex: 1;
  margin-right: 0.5em;
}
.flex-icons {
  display: flex;
  flex-direction: column;
}
.text-align-right {
  text-align: right;
}

.p-btm-10 {
  padding-bottom: 10px;
}
